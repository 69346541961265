.fadeIn {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.displayingImgWhenBig {
  width: 60%;
}

.displayingImgWhenSmall {
  width: 40vw;
}

.imgContainer img:hover {
  opacity: 70%;
}
.button {
  font-size: 1rem;
  color: #555;
  transition: color 0.3s ease;
}

.button:hover {
  color: #000;
  transform: scale(1.45);
}

.imgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.displayingImgWhenBig img {
  width: 70%;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.displayingImgWhenSmall img {
  width: 100%;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.circleImage {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  object-fit: cover;
  opacity: 0.6;
  transition: transform 0.3s, opacity 0.3s;
}

.circleImageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.5rem;
  position: relative;
}

.artistNameOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-decoration: none;
  color: #000;
}

.circleImageContainer:hover .circleImage {
  opacity: 1;
}

.circleImageContainer.focused .circleImage {
  transform: scale(1.2);
  opacity: 0.5;
}
