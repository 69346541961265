/* ToggleSwitch.module.css */
.toggle-switch {
  width: 50px;
  height: 25px;
  background-color: #ccc;
  border-radius: 25px;
  display: flex;
  align-items: center;
  padding: 2px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-switch.on {
  background-color: #4caf50;
}

.toggle-switch-knob {
  width: 21px;
  height: 21px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s;
}

.toggle-switch-knob.on {
  transform: translateX(25px);
}

.toggle-switch-knob.off {
  transform: translateX(0);
}
