.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1; /* Ensure the video stays behind other content */
}

video {
  min-width: 100%;
  min-height: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5); /* Overlay to improve readability of text */
}

.gallery-title {
  font-family: "Gothic A1", sans-serif; /* Apply Gothic A1 font */
  font-size: 48px; /* Adjust font size as needed */
  color: #ffffff; /* Set text color */
  text-align: center; /* Center-align the text */
}

.skip-text {
  color: dimgrey;
  font-family: "Gothic A1", sans-serif; /* Apply Gothic A1 font */
  font-size: 30px;
  opacity: 0;
  transition:
    opacity 1s ease-in-out,
    color 0.8s; /* Use transition for a smooth appearance */
}

.skip-text:hover {
  color: whitesmoke;
}

.skip-text.visible {
  opacity: 1;
}

/* Hide element on phone screens */
@media (max-width: 1000px) {
  .hide-on-phone {
    display: none;
  }
}
