.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.header {
  margin-bottom: 2rem;
  animation: fadeInDown 1s;
}

.subHeader {
  margin-bottom: 1rem;
  animation: fadeInUp 1s;
}

.addressSection,
.orderSection {
  width: 100%;
  max-width: 800px;
  margin-bottom: 2rem;
}

.addressInput {
  animation: fadeInLeft 1s;
}

.orderGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.orderItem {
  animation: fadeInRight 1s;
}

.itemContainer {
  display: flex;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s;
}

.itemContainer:hover {
  transform: scale(1.05);
}

.itemImage {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.itemDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5rem;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.orderButton {
  animation: fadeInUp 1s;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
