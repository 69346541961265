.wrapper {
  display: grid;
  grid-gap: 2vw;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 20rem 10rem 5rem;
  background-color: #fff;
  color: #444;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 5rem;
}

.wrapperWhenSmall {
  grid-template-rows: 10rem 18rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.bigBox:nth-child(1) {
  grid-column: 1 / 3;
  grid-row: 1;
}

.bigBox:nth-child(2) {
  grid-column: 3;
  grid-row: 1 / 4;
}

.bigBox:nth-child(3) {
  grid-column: 1;
  grid-row: 2/4;
}

.bigBox:nth-child(4) {
  grid-column: 2;
  grid-row: 2/4;
}

.bigBox:nth-child(5) {
  grid-column: 4 / 5;
  grid-row: 1 / 4;
}

img {
  object-fit: cover;
  width: 100%;
  max-height: 100%;
  transition: transform 0.4s;
}

.box:nth-child(1) {
  grid-column: 1/5;
  grid-row: 1/2;
}

.box:nth-child(2) {
  grid-column: 1/3;
  grid-row: 2/3;
}

.box:nth-child(3) {
  grid-column: 3/5;
  grid-row: 4/5;
}

.box:nth-child(4) {
  grid-column: 1/3;
  grid-row: 4/5;
}

.box:nth-child(5) {
  grid-column: 3/5;
  grid-row: 2/3;
}

.box:hover {
  transform: scale(1.1);
}

.bigBox:hover {
  transform: scale(1.1);
}
