.button:hover {
  color: darkgrey;
}
.button {
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 0.3s, color 0.3s;
}

.button:hover {
  background-color: grey; /* Hover 배경색 */
  color: white; /* Hover 텍스트 색상 */
}
