.button-80 {
  background: #f5f5f5;
  backface-visibility: hidden;
  border-radius: 0.5rem;
  border: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  color: #333;
  cursor: pointer;
  display: inline-block;
  font-family: "Roboto", Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-80:hover {
  background: #e0e0e0;
  transform: scale(1.05);
}

.button-left {
  margin-right: 0.5rem;
}

.button-80:active {
  background: #d5d5d5;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transform: translateY(2px);
}

.button-80:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(21, 156, 228, 0.4);
}
