.menuItem {
  color: black;
  text-decoration-line: none;
}

.menuItem:hover {
  color: grey;
}

.hidden {
  display: none;
}
